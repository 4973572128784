import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import { Box, Text } from 'grommet'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import Button from './Button'
import { ChevronDown } from './Icons'
import ContentContainer from './ContentContainer'
import FormInputSku from './FormInputSku'
import orderSetProductSku from '../store/actions/orderSetProductSku'

const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  margin-bottom: 0;
`

const SkuSchema = Yup.object().shape({
  sku: Yup.string().required('Required'),
})

const FormSkuSelect = ({
  data,
  product,
  initialValues,
  orderSetProductSku,
}) => {
  return (
    <Box fill>
      <Formik
        initialValues={initialValues}
        isInitialValid={() => SkuSchema.isValidSync(initialValues)}
        validationSchema={SkuSchema}
        validate={async values => {
          const isValid = await SkuSchema.isValid(values)
          if (isValid) {
            await orderSetProductSku({
              product: 'Postcard',
              sku: values.sku,
            })
          }
          return isValid
        }}
        onSubmit={async values => {
          await orderSetProductSku({
            product: 'Postcard',
            sku: values.sku,
            info: {},
          })
          navigate(`/${product.toLowerCase()}/design`)
        }}
        render={({ values, isSubmitting, isValid, errors }) => (
          <StyledForm>
            <Box fill direction="column">
              <ContentContainer justify="center">
                <Box pad={{ vertical: 'small' }}>
                  <Text color="dark-3" size="small">
                    Select Size
                  </Text>
                </Box>
                <Box>
                  <Box gap="medium">
                    {data.map(sku => (
                      <Field
                        component={FormInputSku}
                        sku={sku}
                        key={sku.id}
                        name="sku"
                      />
                    ))}
                  </Box>
                </Box>
              </ContentContainer>
              <Box align="end" margin={{ top: 'medium' }}>
                <Button
                  alignSelf="end"
                  pad="large"
                  primary
                  type="submit"
                  disabled={!isValid}
                  label="Next"
                  reverse
                  icon={<ChevronDown size="small" />}
                />
              </Box>
            </Box>
          </StyledForm>
        )}
      />
    </Box>
  )
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    sku: state.order.productSku,
  },
})

export default connect(
  mapStateToProps,
  { orderSetProductSku }
)(FormSkuSelect)
