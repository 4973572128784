import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Box } from 'grommet'

import mediaTemplates from '../lib/mediaTemplates'
import getBase from '../lib/getBaseVisUnits'

const Container = styled(Box)`
  align-items: flex-start;
  ${mediaTemplates.small`
    align-items: center;
    padding-right: 20%;
  `}
`

const Postcard = styled(Box)`
  background: #f2f2f2;
  box-shadow: ${p =>
    p.interactive
      ? p.isActive
        ? '0 0 70px rgba(0, 0, 0, 0.2)'
        : 'none'
      : '0 0 70px rgba(0, 0, 0, 0.2)'};
  position: relative;
  width: ${p => p.w * getBase('large')}px;
  height: ${p => p.h * getBase('large')}px;
  min-height: ${p => p.h * getBase('large')}px;
  margin-top: ${p => (p.y || 0) * getBase('large')}px;
  left: ${p => (p.x || 0) * getBase('large')}px;
  ${mediaTemplates.medium`
    width: ${p => p.w * getBase('medium')}px;
    height: ${p => p.h * getBase('medium')}px;
    min-height: ${p => p.h * getBase('medium')}px;
    margin-top: ${p => (p.y || 0) * getBase('medium')}px;
    left: ${p => (p.x || 0) * getBase('medium')}px;
  `}
  ${mediaTemplates.small`
    width: ${p => p.w * getBase('small')}px;
    height: ${p => p.h * getBase('small')}px;
    min-height: ${p => p.h * getBase('small')}px;
    margin-top: ${p => (p.y || 0) * getBase('small')}px;
    left: ${p => (p.x || 0) * getBase('small')}px;
  `}
  transition: box-shadow 0.5s;
  z-index: ${p => (p.interactive && p.isActive ? 2 : 0)};
`

const VisualPostcards = ({ interactive, activeSize }) => {
  return (
    <Container
      fill
      style={{ position: 'relative' }}
      pad={{ bottom: 'large' }}
      justify="center"
    >
      <Postcard
        isActive={interactive && activeSize === '6x11-500-firstClass'}
        interactive={interactive}
        x={2}
        y={0}
        w={11}
        h={6}
      />
      <Postcard
        isActive={interactive && activeSize === '5_5x8_5-500-firstClass'}
        interactive={interactive}
        x={0}
        y={-2}
        w={8.5}
        h={5.5}
      />
      <Postcard
        isActive={interactive && activeSize === '4x6-500-firstClass'}
        interactive={interactive}
        x={6}
        y={-3}
        w={6}
        h={4}
      />
    </Container>
  )
}

VisualPostcards.propTypes = {
  interactive: PropTypes.bool,
  activeSize: PropTypes.string,
}

const mapStateToProps = state => ({
  activeSize: state.order.productSku,
})

export default connect(mapStateToProps)(VisualPostcards)
