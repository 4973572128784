import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { get, map } from 'lodash/fp'

const GET_BASE_POSTCARD_SKUS = graphql`
  query GetBasePostcardSkus {
    allStripeSku(
      filter: {
        product: { name: { eq: "Postcard" } }
        attributes: {
          maxQuantity: { eq: "500" }
          mailType: { eq: "First Class" }
        }
      }
    ) {
      edges {
        node {
          id
          active
          price
          attributes {
            size
            mailType
            minQuantity
            maxQuantity
            name
          }
          product {
            id
            name
          }
        }
      }
    }
  }
`

const DataBasePostcards = ({ Component }) => (
  <StaticQuery
    query={GET_BASE_POSTCARD_SKUS}
    render={({ allStripeSku }) => {
      console.log(allStripeSku)
      const data = map(get('node'), allStripeSku.edges)
      return <Component data={data} product={'postcards'} />
    }}
  />
)

export default DataBasePostcards
