import React from 'react'
import { Box, Text } from 'grommet'
import { get, isEqual } from 'lodash/fp'
import styled from 'styled-components'

import Heading from './Heading'

const Detail = styled(Text)`
  font-style: italic;
  display: block;
`

const Container = styled(Box)`
  cursor: pointer;
`

const FormInputSku = ({ sku, field, form }) => {
  const { value } = field
  const isActive = isEqual(get('id', sku), value)
  return (
    <Container
      border="all"
      background={isActive ? 'dark-1' : 'light'}
      pad={{ vertical: 'medium', horizontal: 'small' }}
      onClick={() => form.setFieldValue('sku', get('id', sku))}
      name="sku"
      isActive={isActive}
    >
      <Heading level={4} margin={{ vertical: 'none' }}>
        {get('attributes.name', sku)}
      </Heading>
      <Detail>{get('attributes.size', sku)}</Detail>
    </Container>
  )
}

export default FormInputSku
