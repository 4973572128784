export default function(size) {
  switch (size) {
    case 'small':
      return 20
    case 'medium':
      return 33
    case 'large':
      return 40
    default:
  }
}
