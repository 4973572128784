import React from 'react'
import { Link } from 'gatsby'
import { Box } from 'grommet'
import { Formik, Field, ErrorMessage } from 'formik'

import Button from '../../components/Button'
import Layout from '../../components/layout'
import ContentContainer from '../../components/ContentContainer'
import SEO from '../../components/seo'
import ProductNav from '../../components/ProductNav'
import ColumnStack from '../../components/ColumnStack'
import ResponsiveHidden from '../../components/ResponsiveHidden'
import VisualPostcards from '../../components/VisualPostcards'
import ListProductVariants from '../../components/ListProductVariants'
import FormSkuSelect from '../../components/FormSkuSelect'
import DataBasePostcards from '../../components/DataBasePostcards'
import { ChevronUp } from '../../components/Icons'

const PostcardsIndex = () => (
  <Layout productNav="postcards">
    <SEO title="Postcards" />
    <ColumnStack pad={{ vertical: 'medium' }} fill gap="large">
      <Box flex>
        <ContentContainer side="left">
          <ResponsiveHidden>
            <VisualPostcards interactive />
          </ResponsiveHidden>
        </ContentContainer>
        <Link to="/">
          <Button
            label="Previous"
            icon={<ChevronUp size="small" color="dark-1" />}
          />
        </Link>
      </Box>
      <Box flex>
        <DataBasePostcards justify="center" Component={FormSkuSelect} />
      </Box>
    </ColumnStack>
  </Layout>
)

export default PostcardsIndex
