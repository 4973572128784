import React from 'react'
import { get } from 'lodash/fp'
import styled from 'styled-components'
import { Text, Box } from 'grommet'

import Heading from './Heading'

const Detail = styled(Text)`
  font-style: italic;
  display: block;
`

const ListProductVariants = ({ data }) => {
  return (
    <div>
      {data.map(sku => (
        <Box key={sku.id} border="all" pad="small">
          <Heading level={4}>{get('attributes.name', sku)}</Heading>
          <Detail>{get('attributes.size', sku)}</Detail>
          <Detail>{get('attributes.postage', sku)}</Detail>
        </Box>
      ))}
    </div>
  )
}

export default ListProductVariants
